import React, { ReactNode } from 'react';
import { LocaleSwitch } from './LocaleSwitch';

interface Props {
    locale: string;
    setLocale: (l: string) => void;
}

export const Locales: [string, ReactNode][] = [["ru", "RU"], ["en", "EN"]];

export const DefaultLocaleSwitch = (props: Props) => <LocaleSwitch availableLocales={Locales} {...props} />
