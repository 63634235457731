import React from 'react';
import { AssignmentTurnedInOutlined } from "@mui/icons-material";
import { EditorPlugin } from "../..";
import { createWithVoidInjector } from "../common";
import { ConfigurableFormsEditorBlockElement, ConfigurableFormsEditorBlockElementType } from "./ConfigurableFormsEditorBlockElement";

const withConfigurableForms = createWithVoidInjector(ConfigurableFormsEditorBlockElementType);

/*
 * depends on the ConfigurableFormsSelectionContext
 */ 
export const ConfigurableFormsEditorPlugin: EditorPlugin = {
    key: "configurable-forms",
    inject: withConfigurableForms,
    commands: [
        {
            name: "insert-configurable-form",
            invoke: editor => {
                editor.insertNode({
                    type: ConfigurableFormsEditorBlockElementType,
                    form: null,
                    children: [{ text: "" }],
                } as any);
            },
            menu: {
                section: "insert-item",
                icon: <AssignmentTurnedInOutlined />,
                label: "Configurable form",
                label_id: "forms.editor.insert",
            }
        }
    ],
    customBlocks: { [ConfigurableFormsEditorBlockElementType]: ConfigurableFormsEditorBlockElement },
}; 
