import styled from '@emotion/styled';
import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { BlockSelectionCss } from '../../elements/Common';
import { usePowerEditorContext } from '../../slate/PowerEditorContext';
import { FormControl } from '../../../schemed';
import { useConfigurableFormsSelection } from '../../../ConfigurableForms/useConfigurableFormsSelection';
import { CallMade } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { usePowerEditorSettings } from '../../PowerEditorSettingsContext';
import { FormattedMessage } from 'react-intl';
import { PowerEditorBase } from '../../slate/PowerEditorBase';

export const ConfigurableFormsEditorBlockElementType = "configurable-form-block";

const Wrapper = styled.div<{ isSelected?: boolean }>`
    padding: 0.5rem 1rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    ${props => BlockSelectionCss(props)}

    & > div:last-child {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 0.5rem;
        align-items: end;

        & > [data-slate-editor], > .MuiTypography-caption {
            grid-column: span 2;
        }
    }
`;

export const ConfigurableFormsEditorBlockElement = (props: RenderElementProps) => {
    const { element, attributes, children } = props;
    const [successMessageLocal, setSuccessMessageLocal] = useState<any>((element as any).success_message)
    
    const isSelected = useSelected();
    const { viewMode } = usePowerEditorContext();
    const formsSelection = useConfigurableFormsSelection();

    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, element);
    
    const settings = usePowerEditorSettings();
    const EditorComponent = settings.EditorComponent || PowerEditorBase;

    const updateForm = (form: string) => {
        Transforms.setNodes(
            editor,
            { form } as any,
            { at: path },
        );
    }

    const updateSuccessMessage = (content: any) => {
        setSuccessMessageLocal(content);
        Transforms.setNodes(
            editor,
            { success_message: content } as any,
            { at: path },
        );
    }

    useEffect(
        () => formsSelection.ensureLoaded(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    const selectedFormId = (element as any).form;

    return (
        <Wrapper {...attributes} isSelected={isSelected}>
            {children}

            <div style={{ width: "100%" }} contentEditable={false}>
                <FormControl
                    row={element}
                    field="form"
                    schema={formsSelection.selectorSchema}
                    onChange={(o,c) => updateForm(c.form)}
                    extraProps={{ readOnly: viewMode }}
                    />
                {formsSelection.formsPagesPath &&
                    <Link to={selectedFormId ? `${formsSelection.formsPagesPath}/${selectedFormId}` : formsSelection.formsPagesPath}>
                        <IconButton size="small">
                            <CallMade />
                        </IconButton>
                    </Link>}
                <Typography variant="caption" color="textSecondary" component="p"><FormattedMessage id="forms.editor.success_message" /></Typography>
                <EditorComponent
                    content={successMessageLocal}
                    update={(v: any) => updateSuccessMessage(v)}
                    />
            </div>
        </Wrapper>
    );
}
